<template>
  <div class="online-service">
    <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    <van-list v-if="dataList.length >0">
      <van-cell-group inset>
        <template v-for="(item, index) in dataList" :key="item.id">
          <div v-if="jsBrige" @click="openUrl(item.url)">
            <van-cell is-link :border="false" :title="item.name">
              <template #right-icon>
                <van-icon name="service-o" size="20" />
              </template>
            </van-cell>
          </div>
          <a v-else :href="item.url" target="_blank">
            <van-cell :class="(dataList.length - 1) === index ? 'no-border' : ''" is-link :border="false" :title="item.name">
              <template #right-icon>
                <van-icon name="service-o" size="20" />
              </template>
            </van-cell>
          </a>
        </template>
      </van-cell-group>
    </van-list>
    <div v-else>
      <van-empty :description="$t('common.noData')" />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryOnlineService } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
   
    return {
      goBack,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      dataList: [],
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    this.fetchData();
  },
  
  methods: {
    fetchData() {
      queryOnlineService().then(res => {
        this.dataList = res.data || [];
        if (this.dataList.length === 1 && window.jsBridge) {
          this.openUrl(this.dataList[0].url);
          this.goBack();
        }
      });
    },
    openUrl(url) {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openUrl', url, '');
      }
    },
  },
};
</script>

<style lang="less">
.online-service {
  .van-cell-group {
    margin-top: 20px;
    .van-cell {
      background: transparent;
      border-bottom: 1px solid #d8d8d8;
      &.no-border {
        border-bottom: none;
      }
    }
  }
}
.theme1 .online-service {
  .van-cell-group {
    .van-cell {
      padding: 16px;
      .van-cell__title {
        color: var(--van-primary-color);
        font-weight: 500;
      }
      .van-icon { color: var(--van-primary-color); }
    }
  }
}
</style>
